<template>
  <div class="card card-custom mx-5" >
 
    <div class="card-header mt-5 d-flex justify-content-between align-items-center">
      
    <!-- <div class="card-title d-flex align-items-center">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary "></i>
      </span>
      <h3 class="card-label ml-2 font-poppins">All Businesses</h3>
    </div>
    -->
    <v-text-field
      v-model="search"
      label=""
      hide-details
      outlined
      placeholder="Search here"
      class="complete-border text-indego"
      style="max-width: 300px;"
      @keyup.enter="loadBusinesses"

    >
      <template v-slot:append>
        <v-icon class="cursor-pointer" @click="clearSearch()">mdi-close</v-icon>
        <v-icon class="cursor-pointer" @click="loadBusinesses">mdi-magnify</v-icon>
      </template>
    </v-text-field>

    <v-btn medium class="bg-primary" dark  @click="addBusiness">
            <v-icon left>mdi-plus </v-icon>Add New
          </v-btn>
 
  </div>

    <!-- Data Table -->
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="businesses"
            :server-items-length="totalBusinesses"
            :options.sync="options"
            :loading="loading"
            class="elevation-1"
            :footer-props="{'items-per-page-options': []}"
          >
          <template v-slot:item.active="{ item }">
              <v-chip 
              :class="{
      'bg-primary text-light font-weight-bolder': item.active == 'Active',
      'bg-danger text-light font-weight-bolder': item.active == 'Inactive',
    }"
              
              >
                {{ item.active }}
              </v-chip>
            </template>
            <template v-slot:item.store_name="{ item }">
              <v-chip class="bg-primary text-light font-weight-bolder" @click="goToStore(item)">
                {{ item.store_name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">

              <v-icon mediam class="mr-1 text-primary" @click="viewDetails(item)">
                mdi-eye
              </v-icon>
              <v-icon mediam class="mr-1 text-info" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon mediam class="mr-2 text-danger" @click="deleteBus(item)">
                mdi-delete
              </v-icon>
              <v-icon mediam class="mr-2 text-indego" @click="startImpersonation(item)">
                mdi-login
              </v-icon>

            </template>
          </v-data-table>
          <v-pagination
            v-model="options.page"
            :length="pages"
            :total-visible="7"
            @input="loadBusinesses"
          ></v-pagination>
        </div>
      </div>
    </div>

    <!-- Modal for Business Details -->
    <v-dialog v-model="modalVisible" max-width="800">
  <v-card>
    <v-toolbar dense flat color="transparent">
      <v-toolbar-title class="text-h1">Business Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="modalVisible = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container v-if="selectedBusiness">
        <v-row>
          <v-col cols="6" v-if="selectedBusiness.unique_id">
            <v-text-field v-model="selectedBusiness.unique_id" label="Business ID" disabled></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="selectedBusiness.name" label="Name" disabled></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="selectedBusiness.store_name" label="Store Name" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.user">
            <v-text-field v-model="selectedBusiness.user.name" label="User Name" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.user">
            <v-text-field v-model="selectedBusiness.user.email" label="Email" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.state">
            <v-text-field v-model="selectedBusiness.state" label="State" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.city">
            <v-text-field v-model="selectedBusiness.city" label="City" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.country && selectedBusiness.country.name">
            <v-text-field v-model="selectedBusiness.country.name??''" label="Country" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.menu_details">
            <v-textarea v-model="selectedBusiness.menu_details" label="Menu Details" rows="1" disabled></v-textarea>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.phone">
            <v-text-field v-model="selectedBusiness.phone" label="Phone" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.food_type && selectedBusiness.food_type.name">
            <v-text-field v-model="selectedBusiness.food_type.name" label="Food Type" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.address">
            <v-text-field v-model="selectedBusiness.address" label="Address" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.postal_code">
            <v-text-field v-model="selectedBusiness.postal_code" label="Postal Code" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.http_origin">
            <v-text-field v-model="selectedBusiness.http_origin" label="URL" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.description">
            <v-textarea v-model="selectedBusiness.description" label="Description" rows="1" disabled></v-textarea>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.lat">
            <v-text-field v-model="selectedBusiness.lat" label="Latitude" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.long">
            <v-text-field v-model="selectedBusiness.long" label="Longitude" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.activation_code">
            <v-text-field v-model="selectedBusiness.activation_code" label="Activation Code" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.activation_date">
            <v-text-field v-model="selectedBusiness.activation_date" label="Activation Date" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.active">
            <v-text-field v-model="selectedBusiness.active" label="Status" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.custom_domain">
            <v-text-field v-model="selectedBusiness.custom_domain" label="Custom Domain" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.custom_domain_verify">
            <v-text-field v-model="selectedBusiness.custom_domain_verify" label="Custom Domain Verification" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.default_url">
            <v-text-field v-model="selectedBusiness.default_url" label="Default URL" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.claimed">
            <v-text-field v-model="selectedBusiness.claimed" label="Claimed Status" disabled></v-text-field>
          </v-col>
          <v-col cols="6" v-if="selectedBusiness.suggested_domain">
            <v-text-field v-model="selectedBusiness.suggested_domain" label="Suggested Domain" disabled></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="bg-primary text-light" @click="modalVisible = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<AddBusiness :AddBusinessDialogue="AddBusinessDialogue" @update:AddBusinessDialogue="AddBusinessDialogue = $event"  @loadBusinesses="loadBusinesses"></AddBusiness>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import {  IMPERSONATE } from "@/core/services/store/auth.module";
import AddBusiness from "./AddBusiness.vue";

export default {
  components:{
    AddBusiness
  },
  name: "businesses",
  data() {
    return {
      AddBusinessDialogue:false,
      dialog: false,
      search: '',
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      totalBusinesses: 0,
      businesses: [],
      modalVisible: false,
      selectedBusiness: {},
      headers: [
        { text: "Store Name", align: "left", sortable: false, value: "store_name" },
        { text: "Name", align: "left", sortable: false, value: "name" },
        { text: "User", align: "left", sortable: false, value: "user.name" },
        { text: "Email", align: "left", sortable: false, value: "user.email" },
      
        { text: "City", align: "left", sortable: false, value: "city" },
        { text: "Country", align: "left", sortable: false, value: "country.name" },
        { text: "Status", align: "left", sortable: false, value: "active" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    isAnyFilterApplied() {
      return this.businessName !== "";
    },
    from() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1;
    },
    to() {
      return Math.min(this.options.page * this.options.itemsPerPage, this.totalBusinesses);
    },
    pages() {
      return Math.ceil(this.totalBusinesses / this.options.itemsPerPage);
    }
  },
  watch: {
    options: {
      handler() {
        this.loadBusinesses();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "All Businesses" }]);
    this.loadBusinesses();
  },
  methods: {
    addBusiness(){
      console.log('AddBusinessDialogue',this.AddBusinessDialogue)
      this.AddBusinessDialogue=true
      console.log('AddBusinessDialogue',this.AddBusinessDialogue)

    },
    async startImpersonation(item) {
  try {
    await this.$store.dispatch(IMPERSONATE, item.user.id);
    this.$router.push({ name: "dashboard" });
    // setTimeout(() => {
    //   window.location.reload()
    // }, 300);
  //  window.location.reload()
  } catch (error) {
    console.error('Failed to start impersonation:', error);
    // Optional: handle errors, show a message, etc.
  }


    },
  clearSearch() {
    this.search = ''; // Clear the search input
    this.options.page = 1; // Reset the page to 1 when clearing the search
    this.loadBusinesses(); // Reload or refresh businesses data
  },
  loadBusinesses() {
    this.loading = true;
    ApiService.post("admin/businesses", {
      per_page: this.options.itemsPerPage,
      page: this.options.page,
      search: this.search
    }).then(({ data }) => {
      this.businesses = data.data;
      this.totalBusinesses = data.total;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  },
  viewDetails(item) {
    this.selectedBusiness = item;
    this.modalVisible = true;
  },
  goToStore(business) {
    let storeUrl = 'https://' + business.store_name + '.getmefood.co.uk';
    if (business.custom_domain_verify == 'verified' && business.custom_domain) {
      storeUrl = 'https://' + business.custom_domain;
    }
    window.open(storeUrl, '_blank');
  },
  deleteBus(item) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiService.post("admin/businesses/delete/" + item.user_id).then(() => {
          this.loadBusinesses();
          Swal.fire("Deleted!", "Business has been deleted.", "success");
        });
      }
    });
  },
  edit(item) {
    this.businesses.forEach(business => {
      if (business.id == item.id) {
        this.$store.state.businesses = business;
      }
    });
    this.$router.push({ name: "businesses.edit" });
  }
}

};
</script>

<style scoped>
.card-custom{
  margin-top: 80px;
  border-radius: 20px;
}
.text-indego {
  color: #9B92DD !important;
}
.bg-primary text-light {
  background-color: #9B92DD !important;
}
.v-dialog__container {
  display: block !important;
}
.v-btn.icon {
  min-width: auto;
}
</style>
